import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import TokenInfo from 'components/TokenInfo';
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Consistent spacing encourages visual rhythm that improves the quality of our
user interfaces. Chameleon provides a spacing scale for designers and developers
for applying layout spacing, establishing a shared language and resulting in a
consistent visual balance in our digital products.`}</p>
    <h2 {...{
      "id": "spacing-scale",
      "style": {
        "position": "relative"
      }
    }}>{`Spacing scale`}</h2>
    <p>{`Chameleon's spacing scale is built on increments of 4 pixels. We chose a 4px
baseline because four is a flexible number that can be divided or multiplied and
results in a whole number. All components and typography aligns to this 4px
baseline grid for mobile, tablet, and desktop.`}</p>
    <TokenInfo platform="js" scope="space" mdxType="TokenInfo" />
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`The spacing scale is used where we need to apply horizontal or vertical spacing
for UI components and containers.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stack spacing`}</strong>{`: refers to horizontal spacing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inline spacing`}</strong>{`: refers to vertical spacing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inset spacing`}</strong>{`: refers to spacing inside of a container or component.`}</li>
    </ul>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1639%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "typography",
      "style": {
        "position": "relative"
      }
    }}>{`Typography`}</h2>
    <p>{`Chameleon's typography was carefully built to work harmoniously with our spacing
system. Therefore, line heights are designed with increments of 4px, where
applying space on typographical elements is measured from the edge of the text.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1639%3A2504" mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      